import React, { Component } from "react";
import img11 from '../images/11.png';
import img12 from '../images/12.jpeg';
import img13 from '../images/13.jpeg';
import img14 from '../images/14.jpg';
import img15 from '../images/15.jpg';
import img16 from '../images/16.jpg';
import img17 from '../images/17.jpg';
import img18 from '../images/18.jpg';
import img19 from '../images/19.jpg';
import img20 from '../images/20.jpg';
import img21 from '../images/21.jpg';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default class StroiteljnieMetaloKonstrukcii extends Component {

    render() {
      const { language } = this.props;

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
      };
    return (
      <div className="center-container">
        <div className="carousel" style={{ width: '950px' }}>
          {language === "ru" ? (
             <h4 style={{paddingBottom : '3%'}}> Строительные металлоконструкции </h4>
            ) : (
              <h4 style={{paddingBottom : '3%'}}> Celtniecības konstrukcijas </h4>
          )}
         
          <Slider {...settings}>
            <div>
              <img src={img11}  height="300"/>
            </div>
            <div>
              <img src={img12} height="300"/>
            </div>
            <div>
              <img src={img13} height="300"/>
            </div>
            <div>
              <img src={img14} height="300"/>
            </div>
            <div>
              <img src={img15} height="300"/>
            </div>
            <div>
              <img src={img16} height="300"/>
            </div>
            <div>
              <img src={img17} height="300"/>
            </div>
            <div>
              <img src={img18} height="300"/>
            </div>
            <div>
              <img src={img19} height="300"/>
            </div>
            <div>
              <img src={img20} height="300"/>
            </div>
            <div>
              <img src={img21} height="300"/>
            </div>
          </Slider>
        </div>
      </div>
    );
}
}
