import React, {useState} from 'react';
import header from './metbuve.png';
import { Link } from 'react-router-dom';
import lv from './images/latvia-flag-icon.png';
import ru from './images/russia-flag-icon.png';

const Header = () => {

  return (
    
    <nav className="navbar header">
      <div className="header-container">
        <a href="/">
          <img src={header} alt="Family Tree Builder Online"  height="50"/>
        </a>

        <div className='languages' style={{ position: 'absolute', top: '50%', right: '5%', transform: 'translateY(-50%)' }}>
        <Link to="/">
            <img src={ru} width="30" height="20" style={{ marginRight: "10px" }}/>
          </Link>
          <Link to="/lv">
            <img src={lv} width="30" height="20"  />
          </Link>

        </div>
      </div>
    </nav>
  )
}

export default Header;
