import React, { Component } from "react";
import img43 from '../images/43.jpeg';
import img44 from '../images/44.jpeg';
import img45 from '../images/45.jpeg';
import img46 from '../images/46.jpeg';
import img47 from '../images/47.jpeg';
import img48 from '../images/48.jpeg';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default class DekorotivnieIzdelijaDljaDoma extends Component {
    render() {
      const { language } = this.props;

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
      };
    return (
      <div className="center-container">
        <div className="carousel" style={{ width: '950px' }}>
        {language === "ru" ? (
             <h4 style={{paddingBottom : '3%', paddingTop : '5%'}}> Декоративные изделия, для дома </h4>
            ) : (
             <h4 style={{paddingBottom : '3%', paddingTop : '5%'}}> Dekoratīvie raksti, apvalks </h4>
          )}

          <Slider {...settings}>
            <div>
              <img src={img43} height="300"/>
            </div>
            <div>
              <img src={img44} height="300"/>
            </div>
            <div>
              <img src={img45} height="300"/>
            </div>
            <div>
              <img src={img46} height="300"/>
            </div>
            <div>
              <img src={img47} height="300"/>
            </div>
            <div>
              <img src={img48} height="300"/>
            </div>
          </Slider>
        </div>
      </div>
    );
}
}
