import './App.css';
import Home from './Home.js';
import NotFound from './NotFound.js';
import { useRoutes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return useRoutes([
    {
      path: "/",
      element: <Home language="ru"/>
    },
    {
      path: "/lv",
      element: <Home language="lv"/>
    },
    {
      path: "*",
      element: <NotFound/>
    }    
  ]);
}

export default App;
