import React from 'react';

const Footer = () => {

  return (
    
    <div style={{paddingTop : '4%'}}>
    <footer className="footer p-3">
      <div className="footer-copyright">
        &copy; {new Date().getFullYear()} Copyright:{' '} metbuve.lv
      </div>
    </footer></div>
  )
}

export default Footer;