import React from 'react';
function NotFound() {
  

  return (
    <div>
      <h1>Что-то пошло не так. Нужно вернуться на <a href='/'>главную страницу!</a></h1>
    </div>
  );
}

export default NotFound;