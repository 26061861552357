import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import StroiteljnieMetaloKonstrukcii from './carousels/StroiteljnieMetaloKonstrukcii';
import MetalloKonstrukciiPodOborudovanie from './carousels/MetalloKonstrukciiPodOborudovanie';
import Navesi from './carousels/Navesi';
import LestniciIPerila from './carousels/LestniciIPerila';
import DekorotivnieIzdelijaDljaDoma from './carousels/DekorotivnieIzdelijaDljaDoma';

function Home(props) {

  const { language } = props;

  return (
    
    <div>
      <table style={{ width: '100%', height: '100vh', borderCollapse: 'collapse', borderSpacing: 0 }}>

        <tbody>
            <tr>
              <td style={{padding: 0}}>
                <Header/>
              </td>
            </tr>
            <tr style={{height: '100%'}}>
              <td style={{padding: 0}}>
                <div className='main-container'>
                  <div style={{paddingLeft: '3%', paddingRight:'3%'}}>
                    <br/>
                    {language === "ru" ? (
                      <h3 className='p-5 pl-7 pr-7'>Большой профессиональный опыт, индивидуальный подход к клиенту, адекватная оценка, надежность и порядочность</h3>
                    ) : (
                      <h3 className='p-5 pl-7 pr-7'>Liela profesionāla pieredze, individuāla pieeja katram klientam, adekvāts novērtējums, uzticamība un godprātība</h3>
                    )}
                    
                    <br/><br/>
                    <StroiteljnieMetaloKonstrukcii language={language}/>
                    <br/><br/>
                    <MetalloKonstrukciiPodOborudovanie language={language}/>
                    <br/><br/>
                    <Navesi language={language}/>
                    <br/><br/>
                    <LestniciIPerila language={language}/>
                    <br/><br/>
                    <DekorotivnieIzdelijaDljaDoma language={language}/>
                  </div>
                </div>
                {language === "ru" ? (
                      <div>
                        <h4 style={{paddingTop : '5%'}}> Контактная информация </h4>
                        <h5 style={{paddingTop : '1%'}}> Адрес электронной почты: siametbuve@inbox.lv </h5>
                        <h5 style={{paddingTop : '1%'}}> Мобильный телефон: +371 29256980 </h5>
                      </div>
                    ) : (
                      <div>
                        <h4 style={{paddingTop : '5%'}}> Kontaktinformācija </h4>
                        <h5 style={{paddingTop : '1%'}}> E-pasta adrese: siametbuve@inbox.lv </h5>
                        <h5 style={{paddingTop : '1%'}}> Mobilais: +371 29256980 </h5>
                      </div>
                    )}
                
              </td>
            </tr>
            <tr>
              <td style={{padding: 0}}>
                <Footer />
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Home;