import React, { Component } from "react";
import img22 from '../images/22.jpg';
import img23 from '../images/23.jpg';
import img24 from '../images/24.jpg';
import img25 from '../images/25.jpeg';
import img26 from '../images/26.jpeg';
import img27 from '../images/27.jpg';
import img28 from '../images/28.jpg';
import img29 from '../images/29.jpg';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default class MetalloKonstrukciiPodOborudovanie extends Component {
    render() {
      const { language } = this.props;

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
      };
    return (
      <div className="center-container">
        <div className="carousel" style={{ width: '950px' }}>
        {language === "ru" ? (
             <h4 style={{paddingBottom : '3%', paddingTop : '5%'}}> Металлоконструкции под оборудование, оснастка </h4>
            ) : (
             <h4 style={{paddingBottom : '3%', paddingTop : '5%'}}> Tērauda konstrukcijas iekārtām, iekārtošana </h4>
          )}
          
          <Slider {...settings}>
            <div>
              <img src={img22}  height="300"/>
            </div>
            <div>
              <img src={img23} height="300"/>
            </div>
            <div>
              <img src={img24} height="300"/>
            </div>
            <div>
              <img src={img25} height="300"/>
            </div>
            <div>
              <img src={img26} height="300"/>
            </div>
            <div>
              <img src={img27} height="300"/>
            </div>
            <div>
              <img src={img28} height="300"/>
            </div>
            <div>
              <img src={img29} height="300"/>
            </div>
          </Slider>
        </div>
      </div>
    );
}
}
