import React, { Component } from "react";
import img30 from '../images/30.jpeg';
import img31 from '../images/31.jpeg';
import img32 from '../images/32.jpg';
import img33 from '../images/33.jpeg';
import img34 from '../images/34.jpeg';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default class Navesi extends Component {
    render() {
      const { language } = this.props;

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
      };
    return (
      <div className="center-container">
        <div className="carousel" style={{ width: '950px' }}>

          {language === "ru" ? (
             <h4 style={{paddingBottom : '3%', paddingTop : '5%'}}> Навесы </h4>
            ) : (
             <h4 style={{paddingBottom : '3%', paddingTop : '5%'}}> Nojumes </h4>
          )}
          
          <Slider {...settings}>
            <div>
              <img src={img30}  height="300"/>
            </div>
            <div>
              <img src={img31} height="300"/>
            </div>
            <div>
              <img src={img32} height="300"/>
            </div>
            <div>
              <img src={img33} height="300"/>
            </div>
            <div>
              <img src={img34} height="300"/>
            </div>
          </Slider>
        </div>
      </div>
    );
}
}
