import React, { Component } from "react";
import img35 from '../images/35.jpeg';
import img36 from '../images/36.jpg';
import img37 from '../images/37.jpg';
import img38 from '../images/38.jpg';
import img39 from '../images/39.jpg';
import img40 from '../images/40.jpg';
import img41 from '../images/41.jpg';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default class LestniciIPerila extends Component {
    render() {
      const { language } = this.props;

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
      };
    return (
      <div className="center-container">
        <div className="carousel" style={{ width: '950px' }}>
        {language === "ru" ? (
             <h4 style={{paddingBottom : '3%', paddingTop : '5%'}}> Лестницы, перила </h4>
            ) : (
             <h4 style={{paddingBottom : '3%', paddingTop : '5%'}}> Kāpnes, margas </h4>
          )}

          <Slider {...settings}>
            <div>
              <img src={img35}  height="300"/>
            </div>
            <div>
              <img src={img36} height="300"/>
            </div>
            <div>
              <img src={img37} height="300"/>
            </div>
            <div>
              <img src={img38} height="300"/>
            </div>
            <div>
              <img src={img39} height="300"/>
            </div>
            <div>
              <img src={img40} height="300"/>
            </div>
            <div>
              <img src={img41} height="300"/>
            </div>
          </Slider>
        </div>
      </div>
    );
}
}
